/** @jsx jsx */
import { css, keyframes } from "@emotion/core";

const colors = {
  black: "#000000",
  white: "#FFFFFF",
  orange: "#ff7d05",
};

const fadeInKeyframes = keyframes`
    0%, 50% {
        opacity:0;
    }
    100% {
        opacity:1;
    }    
`;

export const appStyles = {
  mainSection: {
    wrapper: css`
      height: 100vh;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${colors.black};
    `,
    video: css`
      width: 100vw;
      position: absolute;
      pointer-events: none;
      z-index: 0;
      @media (max-width: 600px) {
        margin-top: -35vw;
      }
    `,

    comingSoonSection: css`
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 2.5vw;
      @media (max-width: 600px) {
        margin-top: -15vw;
      }
      padding-top: 20vw;
    `,
    tutorialBanner: css`
      width: 40vw;
      height: calc(40vw / 9.06896551724137931034);
      @media (max-width: 600px) {
        width: 70vw;
        height: calc(70vw / 9.06896551724137931034);
      }
      background-image: url(/images/tutorials_banner.png);
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0.9;
    `,
    comingSoonText: css`
      animation: ${fadeInKeyframes} 2s ease;
      text-align: center;
      @media (max-width: 600px) {
        margin-top: 80vw;
      }
      h2 {
        margin-top: 1.2vw;
        font-size: 2.3vw;
        @media (max-width: 600px) {
          font-size: 8vw;
        }
        color: ${colors.orange};
        text-shadow: 2px 2px 2px ${colors.black};
        font-weight: 400;
        margin-bottom: 0.45vw;
      }
      p {
        font-size: 1.35vw;
        @media (max-width: 600px) {
          font-size: 4vw;
        }
        color: ${colors.white};
        margin: 0 0 0.45vw 0;
        a {
          color: ${colors.white};
        }
      }
    `,
    buyButtons: css`
      display: flex;
      #buy-btn-container {
        padding: 20px 60px !important;
      }
      @media (max-width: 600px) {
        flex-direction: column;
        margin-top: 10vw;
        #buy-btn-container {
          padding: 0px !important;
        }
      }
    `,
  },
};
