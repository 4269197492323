/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import "./App.css";
import { appStyles } from "./statics/styles/appStyle";

function App() {
  return (
    <section css={appStyles.mainSection.wrapper}>
      <video
        css={appStyles.mainSection.video}
        muted
        playsInline
        loop
        autoPlay
        poster="/video/brandexplode/FXM_WebLoop.jpg"
      >
        <source src="/video/brandexplode/FXM_WebLoop.mp4" type="video/mp4" />
      </video>
      <div css={appStyles.mainSection.comingSoonSection}>
        <div css={appStyles.mainSection.tutorialBanner}></div>
        <div css={appStyles.mainSection.comingSoonText}>
          <h2>COMING SOON</h2>
          <p>
            Find all tutorials <a href="https://bit.ly/37isfmY">here</a>{" "}
          </p>
          <div css={appStyles.mainSection.buyButtons}>
            <div id="buy-btn-container">
              <div className="cgc-buy-btn-pos">
                <a
                  className="cgc-buy-btn small orange"
                  href="https://bit.ly/3pviTv0"
                >
                  <span className="cgc-btn-text">Get 101 Now!</span>
                  <span className="cgc-btn-img"></span>
                </a>
              </div>
            </div>
            <div id="buy-btn-container">
              <div className="cgc-buy-btn-pos">
                <a
                  className="cgc-buy-btn small orange"
                  href="https://bit.ly/2No79wu"
                >
                  <span className="cgc-btn-text">Get 102 Now!</span>
                  <span className="cgc-btn-img"></span>
                </a>
              </div>
            </div>
            <div id="buy-btn-container">
              <div className="cgc-buy-btn-pos">
                <a
                  className="cgc-buy-btn small orange"
                  href="https://bit.ly/37isfmY"
                >
                  <span className="cgc-btn-text">101+102 !!!</span>
                  <span className="cgc-btn-img"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default App;
